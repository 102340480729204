import React from 'react';
import './LandingPage.css'; // Import the CSS file
import mulchImage from './images/mulch.jpg'; // Import the image

const LandingPage = () => {
  return (
    <div className="LandingPage">
      <header className="LandingPage-header">
        <h1 className="LandingPage-title">Top Quality Mulch</h1>
        <h2 className="LandingPage-subtitle">Locally sourced in Emerald, QLD.</h2>

        {/* Hero image */}
        <div className="LandingPage-hero">
          <img src={mulchImage} alt="Mulch Image" />
        </div>

        {/* Call-to-action buttons */}
        <div className="LandingPage-buttons">
          <a href="#services" className="LandingPage-link">Pricing</a>
          <a href="#contact" className="LandingPage-link">Contact Us</a>
        </div>
      </header>

      {/* Footer */}
      <footer className="LandingPage-footer">
        <p>&copy; 2024 Point Above Consulting Pty Ltd. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
